import styles from './AdminTrialsList.module.scss';
import Container from 'components/Container/Container';
import DataTable from 'components/DataTable/DataTable';
import Layout from 'components/Layout/Layout';
import Client from 'api/client';
import { useTableFilter } from 'store';
import React, { useState, useEffect } from 'react';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import { navigate } from 'gatsby';
import {
  unstable_useFormState as useFormState,
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_FormRadioGroup as FormRadioGroup,
  unstable_FormRadio as FormRadio,
  unstable_Form as Form,
} from 'reakit/Form';
import { usePopoverState, Popover, PopoverDisclosure } from 'reakit/Popover';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import InputField from '@moonshineragency/ui/src/components/InputField/InputField';
import classNames from 'classnames';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import Pill from '@moonshineragency/ui/src/components/Pill/Pill';
import Radio from '@moonshineragency/ui/src/components/Radio/Radio';

const columns = [
  {
    Header: 'NCT ID',
    accessor: 'nct_id',
    columnPrefix: 'trial',
  },
  {
    Header: 'Title',
    accessor: 'brief_title',
    columnPrefix: 'trial',
  },
  {
    Header: 'Organization',
    accessor: 'organization',
    columnPrefix: 'rawTrial',
  },
  {
    Header: 'Type',
    accessor: 'study_type',
    columnPrefix: 'trial',
  },
  {
    Header: 'Primary benefit',
    accessor: 'primary_benefit',
    columnPrefix: 'trial',
  },
  {
    Header: 'Recruitment',
    accessor: 'overall_recruitment_status',
    columnPrefix: 'rawTrial',
  },
  {
    Header: 'Imported',
    accessor: 'imported_at',
    columnPrefix: 'rawTrial',
    className: styles.whitespaceNowrap,
  },
  {
    Header: 'Updated',
    accessor: 'updated_at',
    columnPrefix: 'rawTrial',
    className: styles.whitespaceNowrap,
  },
  {
    Header: 'Modified',
    accessor: 'modified_at',
    columnPrefix: 'trial',
    className: styles.whitespaceNowrap,
  },
  {
    Header: 'Published',
    accessor: 'published_at',
    columnPrefix: 'trial',
    className: styles.whitespaceNowrap,
  },
  {
    Header: 'Curation',
    accessor: 'curation_status',
    columnPrefix: 'trial',
    className: styles.curation,
  },
];

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const formatDate = date => {
  if (date === null) {
    return 'Never';
  }

  const dateObject = new Date(date);
  const dateDay = dateObject.getDate();
  const formattedDateDay = dateDay > 9 ? dateDay : `0${dateDay}`;
  const dateMonth = months[dateObject.getMonth()];
  const dateYear = dateObject.getFullYear();

  return `${formattedDateDay} ${dateMonth} ${dateYear}`;
};

const AdminTrialsList = ({
  layoutStructure = {
    showBreadCrumbs: false,
    showTitle: false,
  },
}) => {
  const store = useTableFilter();

  const savedFilters = store.tableFilter;

  const [searchData, setSearchData] = useState(null);
  const [sort, setSort] = useState({
    column: savedFilters?.sort_column,
    direction: savedFilters?.sort_direction,
  });
  const [dateValueFrom, setDateValueFrom] = useState(null);
  const [dateValueTo, setDateValueTo] = useState(null);

  const popoverId = usePopoverState();
  const popoverTitle = usePopoverState();
  const popoverOrganization = usePopoverState();
  const popoverTrialType = usePopoverState();
  const popoverDate = usePopoverState();

  const handleTableRowClick = row => {
    navigate(`/admin/trials/${row.original.nct_id}/edit`);
  };

  const form = useFormState({
    values: {
      search_id: '',
      search_title: '',
      search_organization: '',
      search_trial_type: null,
      search_date_from: null,
      search_date_to: null,
      page: 1,
      sort_column: null,
      sort_direction: null,
    },
    onSubmit: async (values, errors) => {
      setDateValueFrom(values.search_date_from);
      setDateValueTo(values.search_date_to);

      store.setTableFilter({
        sort_column: values.sort_column,
        sort_direction: values.sort_direction,
        search_id: values.search_id,
        search_title: values.search_title,
        search_organization: values.search_organization,
        search_trial_type: values.search_trial_type,
        search_date_from: values.search_date_from,
        search_date_to: values.search_date_to,
      });
      try {
        const response = await Client.trialListAdmin.get(
          values.page,
          values.sort_column,
          values.sort_direction,
          values.search_id,
          values.search_title,
          values.search_organization,
          values.search_trial_type,
          values.search_date_from,
          values.search_date_to,
        );
        setSearchData(response.data);
      } catch (e) {
        throw errors;
      }

      popoverId.hide();
      popoverTitle.hide();
      popoverOrganization.hide();
      popoverTrialType.hide();
      popoverDate.hide();
    },
  });

  const isSearchIdGiven = !!form?.values?.search_id;
  const isSearchTitleGiven = !!form?.values?.search_title;
  const isSearchOrganizationGiven = !!form?.values?.search_organization;
  const isTrialTypeGiven = !!form?.values?.search_trial_type;
  const isSearchDateFromGiven = !!form?.values?.search_date_from;
  const isSearchDateToGiven = !!form?.values?.search_date_to;

  const dataFetcher = async (
    page = 1,
    sortColumn = savedFilters?.sort_column,
    sortDirection = savedFilters?.sort_direction,
    id = savedFilters?.search_id,
    title = savedFilters?.search_title,
    organization = savedFilters?.search_organization,
    trialType = savedFilters?.search_trial_type,
    dateFrom = savedFilters?.search_date_from,
    dateTo = savedFilters?.search_date_to,
  ) => {
    const response = await Client.trialListAdmin.get(
      page,
      sortColumn,
      sortDirection,
      id,
      title,
      organization,
      trialType,
      dateFrom,
      dateTo,
    );

    return response.data;
  };

  useEffect(() => {
    form.update('sort_column', savedFilters?.sort_column || sort.column);
    form.update(
      'sort_direction',
      savedFilters?.sort_direction || sort.direction,
    );
    form.update('search_id', savedFilters?.search_id);
    form.update('search_title', savedFilters?.search_title);
    form.update('search_organization', savedFilters?.search_organization);
    form.update('search_trial_type', savedFilters?.search_trial_type);
    form.update('search_date_from', savedFilters?.search_date_from);
    form.update('search_date_to', savedFilters?.search_date_to);
    setDateValueFrom(savedFilters?.search_date_from);
    setDateValueTo(savedFilters?.search_date_to);
  }, [sort, savedFilters]);

  const rowFormatter = cell => {
    const dateColumns = [
      'imported_at',
      'updated_at',
      'modified_at',
      'published_at',
    ];
    if (dateColumns.includes(cell.column.id)) {
      const formattedDate = formatDate(cell.value);

      return formattedDate;
    }
    if (typeof cell.value === 'object' && cell.value !== null) {
      return cell.value.name;
    }
    return cell.render('Cell');
  };

  const getSortDirection = column => {
    const stateSortDirection = sort.direction;
    const stateColumn = sort.column;

    if (stateSortDirection === 'DESC' && column === stateColumn) {
      return 'ASC';
    }

    return 'DESC';
  };

  const handleSortByColumn = column => {
    const prefixedColumn = `${column.columnPrefix}.${column.id}`;

    setSort({
      column: prefixedColumn,
      direction: getSortDirection(prefixedColumn),
    });

    store.setTableFilter({
      ...savedFilters,
      sort_column: prefixedColumn,
      sort_direction: getSortDirection(prefixedColumn),
    });
    form.update('sort_column', prefixedColumn);
    form.update('sort_direction', getSortDirection(prefixedColumn));
  };

  const handleResetSearch = formField => {
    if (formField === 'search_id') {
      form.values.search_id = '';
      store.setTableFilter({
        search_id: '',
      });
    }

    if (formField === 'search_title') {
      form.values.search_title = '';
      store.setTableFilter({
        search_title: '',
      });
    }

    if (formField === 'search_organization') {
      form.values.search_organization = '';
      store.setTableFilter({
        search_organization: '',
      });
    }

    if (formField === 'search_trial_type') {
      form.values.search_trial_type = null;
      store.setTableFilter({
        search_trial_type: null,
      });
    }

    if (
      formField === 'search_date' ||
      formField === 'search_date_from' ||
      formField === 'search_date_to'
    ) {
      setDateValueFrom(null);
      setDateValueTo(null);

      form.values.search_date_from = '';
      form.values.search_date_to = '';
      store.setTableFilter({
        search_date_from: '',
        search_date_to: '',
      });
    }

    form.submit();

    if (popoverId.visible) {
      popoverId.hide();
    }

    if (popoverTitle.visible) {
      popoverTitle.hide();
    }

    if (popoverOrganization.visible) {
      popoverOrganization.hide();
    }

    if (popoverTrialType.visible) {
      popoverTrialType.hide();
    }

    if (popoverDate.visible) {
      popoverDate.hide();
    }
  };

  const renderPopover = (
    column,
    popoverState,
    fieldName,
    secondFieldName,
    isValueGiven,
    isSecondValueGiven,
  ) => {
    return (
      <div className={styles.popOverHeaderWrapper}>
        {column.Header}
        <PopoverDisclosure
          className={styles.popoverDisclosure}
          {...popoverState}
        >
          <IconWrapper
            className={{
              [styles.ispopoverOpen]: popoverState.visible,
            }}
            Icon={IconComponents.SearchIconSmall}
          />
        </PopoverDisclosure>
        <Popover className={styles.popover} {...popoverState}>
          <div className={styles.popoverWrapper}>
            <Form {...form}>
              <div>
                {column.id === 'imported_at' ? (
                  <>
                    <InputField
                      label="From"
                      name={fieldName}
                      type="date"
                      {...form}
                      onChange={setDateValueFrom(null)}
                      value={dateValueFrom ?? dateValueFrom}
                    />
                    <InputField
                      label="To"
                      name={secondFieldName}
                      type="date"
                      {...form}
                      onChange={setDateValueTo(null)}
                      value={dateValueTo ?? dateValueTo}
                    />
                  </>
                ) : (
                  <InputField
                    name={fieldName}
                    icon={IconComponents.SearchIconSmall}
                    {...form}
                    placeholder="Search..."
                  />
                )}
              </div>

              <div
                className={classNames(styles.searchPopOverActions, {
                  [styles.applySearchButton]:
                    !isValueGiven && !isSecondValueGiven,
                })}
              >
                {(isValueGiven || isSecondValueGiven) && (
                  <Button
                    theme="link"
                    onClick={() => handleResetSearch(fieldName)}
                  >
                    Reset
                  </Button>
                )}

                <FormSubmitButton
                  as={Button}
                  theme="secondary"
                  size="default"
                  {...form}
                >
                  Apply
                </FormSubmitButton>
              </div>
            </Form>
          </div>
        </Popover>
      </div>
    );
  };

  const renderRadioPopover = (column, popover, fieldName, isValueGiven) => {
    return (
      <div className={styles.popOverHeaderWrapper}>
        {column.Header}
        <PopoverDisclosure className={styles.popoverDisclosure} {...popover}>
          <IconWrapper
            className={{
              [styles.ispopoverOpen]: popover.visible,
            }}
            Icon={IconComponents.SearchIconSmall}
          />
        </PopoverDisclosure>
        <Popover className={styles.popover} {...popover}>
          <div className={styles.popoverWrapper}>
            <Form {...form}>
              <div>
                <FormRadioGroup
                  {...form}
                  name={fieldName}
                  className={styles.radioGroup}
                >
                  <div className={styles.radioWrapper}>
                    <FormRadio
                      as={Radio}
                      {...form}
                      name={fieldName}
                      value="observational"
                      label="Observational"
                      checked
                    />
                  </div>
                  <div className={styles.radioWrapper}>
                    <FormRadio
                      as={Radio}
                      {...form}
                      name={fieldName}
                      value="interventional"
                      label="Interventional"
                    />
                  </div>
                </FormRadioGroup>
              </div>

              <div
                className={classNames(styles.searchPopOverActions, {
                  [styles.applySearchButton]: !isValueGiven,
                })}
              >
                {isValueGiven && (
                  <Button
                    theme="link"
                    onClick={() => {
                      handleResetSearch(fieldName);
                    }}
                  >
                    Reset
                  </Button>
                )}
                <FormSubmitButton
                  as={Button}
                  theme="secondary"
                  size="default"
                  {...form}
                >
                  Apply
                </FormSubmitButton>
              </div>
            </Form>
          </div>
        </Popover>
      </div>
    );
  };

  const headerFormatter = column => {
    if (column.id === 'nct_id') {
      return renderPopover(column, popoverId, 'search_id', isSearchIdGiven);
    }

    if (column.id === 'brief_title') {
      return renderPopover(
        column,
        popoverTitle,
        'search_title',
        isSearchTitleGiven,
      );
    }

    if (column.id === 'organization') {
      return renderPopover(
        column,
        popoverOrganization,
        'search_organization',
        isSearchOrganizationGiven,
      );
    }

    if (column.id === 'study_type') {
      return renderRadioPopover(
        column,
        popoverTrialType,
        'search_trial_type',
        isTrialTypeGiven,
      );
    }

    if (column.id === 'imported_at') {
      return renderPopover(
        column,
        popoverDate,
        'search_date_from',
        'search_date_to',
        isSearchDateFromGiven,
        isSearchDateToGiven,
      );
    }

    return column.render('Header');
  };

  return (
    <Layout title="All Trials" isAdmin layoutStructure={layoutStructure}>
      <Container size="wide">
        <Heading size="h2" theme="primary">
          All trials
        </Heading>
        <div className={styles.searchPillsWrapper}>
          {(isSearchIdGiven ||
            isSearchTitleGiven ||
            isSearchOrganizationGiven ||
            isTrialTypeGiven ||
            isSearchDateFromGiven ||
            isSearchDateToGiven) &&
            'Filtered by'}
          <div className={styles.searchedByItems}>
            {isSearchIdGiven && (
              <Pill onClick={() => handleResetSearch('search_id')}>
                Trial ID : {form.values.search_id}
              </Pill>
            )}
            {isSearchTitleGiven && (
              <Pill onClick={() => handleResetSearch('search_title')}>
                Brief title : {form.values.search_title}
              </Pill>
            )}
            {isSearchOrganizationGiven && (
              <Pill onClick={() => handleResetSearch('search_organization')}>
                Organization : {form.values.search_organization}
              </Pill>
            )}
            {isTrialTypeGiven && (
              <Pill onClick={() => handleResetSearch('search_trial_type')}>
                Trial type : {form.values.search_trial_type}
              </Pill>
            )}
            {(isSearchDateFromGiven || isSearchDateToGiven) && (
              <Pill onClick={() => handleResetSearch('search_date')}>
                Date{' '}
                {isSearchDateFromGiven &&
                  `from ${form.values.search_date_from}`}
                {isSearchDateToGiven && ` to ${form.values.search_date_to}`}
              </Pill>
            )}
          </div>
        </div>

        <DataTable
          onTableRowClick={row => handleTableRowClick(row)}
          dataFetcher={dataFetcher}
          rowFormatter={rowFormatter}
          columns={columns}
          setSorting={setSort}
          headerFormatter={headerFormatter}
          searchData={searchData}
          savedFilters={savedFilters}
          handleSortByColumn={handleSortByColumn}
          sort={sort}
        />
        <br />
      </Container>
    </Layout>
  );
};

export default AdminTrialsList;
